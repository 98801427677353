import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/Content/:id',
    name: 'Content',
    component: () => import('../views/Content.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/Content-EN/:id',
    name: 'Content-EN',
    component: () => import('../views/Content-EN.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/Detail/:id/:title',
    name: 'Detail',
    component: () => import('../views/Detail.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/Detail-EN/:id/:title',
    name: 'Detail-EN',
    component: () => import('../views/Detail-EN.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/Mobile.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/mobileIndex',
    name: 'MobileIndex',
    component: () => import('../views/MobileIndex.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/mobileIndexEnglish',
    name: 'MobileIndexEN',
    component: () => import('../views/MobileIndexEN.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
  {
    path: '/Audio/:number',
    name: 'Audio',
    component: () => import('../views/Audio.vue'),
    meta: {
      title: '官网 盐阜农耕文化园旅游景区',
    },
  },
]

const router = new VueRouter({
  routes,
})

export default router
